header {
    position: fixed;
    z-index: 10;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(180deg, black, transparent);

  }
  header .logo {
    padding: 2px 10px 2px 3px;
    border-radius: 10px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    margin: 20px;
    z-index: 10;
  }
  header .logo img {
    height: 75px;
  }
  .logo a {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;
    font-weight: 700;
    font-size: 13px;
    gap: 10px;
    text-shadow: 2px 4px 9px #0000008a;   
  }

  

.burger-menu {
    margin: 20px;
}
.burger-menu .menu-check {
    display: none;
}
.burger-menu .menu-open {
    border: 2px solid #fff;
    padding: 10px 20px;
    border-radius: 100%;
    position: relative;
    box-shadow: 1px 1px 3px;
    cursor: pointer;
    z-index: 1;
}
.burger-menu .menu-open span {
    width: 23px;
    height: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -2px;
    margin-left: -11px;
    background-color: #fff;
    font-weight: bold;
    opacity: 1;
    transition: 0.3s;
}
.burger-menu .menu-open span.burger1 {
    transform: translate3d(0px, -10px, 0px);
}
.burger-menu .menu-open span.burger2 {
    transform: translate3d(0px, 0px, 0px);
    transition: 0.1s;
}
.burger-menu .menu-open span.burger3 {
    transform: translate3d(0px, 10px, 0px);
}
.burger-menu .menu-check:checked + .menu-open {
    background: #fff;
    border: 0;
}
.burger-menu .menu-check:checked + .menu-open span {
    background-color: #dc3901;
}
.burger-menu .menu-check:checked + .menu-open span.burger1 {
    transform: translate3d(-4px, 1px, 0px) rotate(-45deg);
    width: 30px;
}
.burger-menu .menu-check:checked + .menu-open span.burger2 {
    opacity: 0;
}
.burger-menu .menu-check:checked + .menu-open span.burger3 {
    transform: translate3d(-3px, 2px, 0px) rotate(45deg);
    width: 30px;
}
.burger-menu .menu-options {
    background-color: #dc3901ed;
    position: absolute;
    width: 27%;
    top: 0;
    padding: 0;
    opacity: 0;
    transition: 0.3s;
    right: 36.5%;
    display:none;
}
.burger-menu .menu-options li {
    color: #fff;
    text-align: center;
    list-style: none;
    display: block;
    font-weight: normal;
}
.burger-menu .menu-options li a {
    text-decoration: none;
    color: #fff;
    line-height: 4em;
    display: block;
    transition: 0.5s;
}
.burger-menu .menu-options li a:hover {
    background: #65b74d;
}
.burger-menu .menu-options li.search {
    margin-bottom: 1em;
}

.burger-menu .menu-check:checked ~ .menu-options {
    width: 27%;
    opacity: 1;
    top: 104px;
    right: 36.5%;
    display:block;
}