.ReactModal__Content.ReactModal__Content--after-open {
  position: absolute;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 0 !important;
  transform: translate(-50%, -50%);
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .ReactModal__Overlay--after-open {
    opacity: 1;
  }
  
  .ReactModal__Overlay--before-close {
    opacity: 0;
  }
  
  
  .ReactModal__Content img {
    max-width: 100%;
    max-height: 90vh;
  }
  @media (min-width: 992px){

    .ReactModal__Content.ReactModal__Content--after-open {
      inset: 50% auto auto 50% !important;
    }
  }

  @media (max-width: 992px){

    .ReactModal__Content.ReactModal__Content--after-open {
      inset: 50% -50% auto 50% !important;
    }
  }

 .ReactModal__Content button h4 {
  margin: 0;
  padding: 0;
  line-height: 0;
}
.ReactModal__Content button {
  background: #000;
  border: none;
  padding: 13px 10px;
  bottom: 50%;
  right: 0;
  position: absolute;
  cursor: pointer;
  width: 28px;
}

.ReactModal__Content button.close {
  background: #000;
  border: none;
  padding: 13px 10px;
  bottom: 5px;
  left: 50%;

}

.ReactModal__Content .next {

  bottom: 50%;
  right: 0;
}
.ReactModal__Content .prev {
  bottom: 50%;
  left: 0;
}
.ReactModal__Content .prev::after {
  content: "<";
  font-size: 14px;
  font-family: "Righteous", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #fff;
  margin: 0;
  padding: 0;
  line-height: 0;

}

.ReactModal__Content .next::after {

  content: ">";
  font-size: 14px;
  font-family: "Righteous", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #fff;
  margin: 0;
  padding: 0;
  line-height: 0;
}