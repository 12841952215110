@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(221 174 238 / 18%);
  background: radial-gradient(circle, rgb(221 174 238 / 30%) 0%, rgb(2 6 0 / 67%) 100%); z-index: 3;
  margin:0;
}
h1, h2, h3,h4 {
  font-family: "Righteous", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #fff;
}
h1 {

  font-size: 4rem;
  margin: 0;
}
.herovideo-container{
  width: 100vw;
  height: 100vh;
}
    
iframe.hero-video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100vh;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 2;
}
  @media (min-aspect-ratio: 16/9) {
    iframe.hero-video {
      /* height = 100 * (9 / 16) = 56.25 */
      height: 56.25vw;
    }
  }
      
  @media (max-aspect-ratio: 16/9) {
    iframe.hero-video {
      /* width = 100 / (9 / 16) = 177.777777 */
      width: 177.78vh;
    }
  }
  .hero-text {
    position: absolute;
    color: #ffffffeb;
    left: 10%;
    bottom: 5%;
    z-index: 4;
    max-width: 85%;
    text-shadow: 2px 4px 9px #0000008a;
    display: flex;
    align-items: center;
    gap: 20px;
  }
@media (max-width: 920px){

  .hero-text {
    flex-direction: column;
  }
  .hero-text h2 {
    font-size: 16px;
  }
}
.button.button-primary {
  text-decoration: none;
  background: #d33a05;
  color: #ffff;
  padding: 10px 15px;
  border-radius: 10px;
  text-transform: uppercase;
  transition: 0.5s;
}
.button.button-primary:hover {
  background: #fff;
  color: #d33a05;
}
  .wrapper-video {
    overflow: hidden;
    max-width: 100%;
    position: relative;
 }

 .overlay-bg {
  position: absolute;
  color: #ffffff00;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100vh;
  background: rgb(221 174 238 / 18%);
  background: radial-gradient(circle, rgb(221 174 238 / 30%) 0%, rgb(2 6 0 / 67%) 100%); z-index: 3;
}


.slide-in {
  position:fixed;
  top: 0;
  left: 0;
  width: 100%;
  height:100vh;
  background: #0f0f0f;
  transform-origin: bottom;
}
.slide-out {
  position:fixed;
  top: 0;
  left: 0;
  width: 100%;
  height:100vh;
  background: #0f0f0f;
  transform-origin: top;
}


.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  font-size: 18px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mySwiper .swiper-pagination-bullet-active {
  background: #fff;
  border: 1px solid #0000008f;
}

.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 0px;
}
.grid-obras {
  display: grid;

  gap: 20px;
}
@media (min-width:992px) {
  .grid-obras {
    grid-template-columns: repeat(5, 1fr);

  }

}
.item-grid {
  min-height: 340px;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  min-height: 340px;
  align-items: end;
  padding: 20px;
  transition: 0.7s;
 -webkit-box-shadow: 1px 3px 24px -13px rgba(66, 68, 90, 1);
-moz-box-shadow: 1px 3px 24px -13px rgba(66, 68, 90, 1);
box-shadow: 1px 3px 24px -13px rgba(66, 68, 90, 1);
}
.item-grid:hover {
  transform: scale(1.5) translate(-14px, 0);
}

.grid-obras h3 {
  color: #fff;
}

.item-grid-container {
  text-align: center;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 3px 8px 1px rgba(66, 68, 90, 1);
  border-radius: 5px;
} 

.item-grid-container h3 {
  position: absolute;
    bottom: 0;
    padding: 20px;
}

.item-grid.entre-mezclas {
  background-image:
  linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 75%,  rgba(1, 13, 24, 99) 95%), url(./grid-images/entremezclas.jpg);
}

.item-grid.entre-mezclas:hover {
  background-image:
  linear-gradient(180deg, rgba(255, 255, 255, 0),
  rgba(1, 13, 24, 0.5) 90.71%), url(./grid-images/entremezclas.jpg);
}

.item-grid.gargola {
  background-image:
  linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 75%,  rgba(1, 13, 24, 99) 95%), url(./grid-images/gargola.jpg);
}
.item-grid.gargola:hover {
  background-image:
  linear-gradient(180deg, rgba(255, 255, 255, 0),
  rgba(1, 13, 24, 0.5) 90.71%), url(./grid-images/gargola.jpg);
}

.item-grid.habitantes {
  background-image:
  linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 75%,  rgba(1, 13, 24, 99) 95%), url(./grid-images/habitantes.jpg);
}
.item-grid.habitantes:hover {
  background-image:
  linear-gradient(180deg, rgba(255, 255, 255, 0),
  rgba(1, 13, 24, 0.5) 90.71%), url(./grid-images/habitantes.jpg);
}
.item-grid.memorias {
  background-image:
  linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 75%,  rgba(1, 13, 24, 99) 95%), url(./grid-images/memorias.jpg);
}

.item-grid.memorias:hover {
  background-image:
  linear-gradient(180deg, rgba(255, 255, 255, 0),
  rgba(1, 13, 24, 0.5) 90.71%), url(./grid-images/memorias.jpg);
}

.item-grid.amoradanza {
  background-image:
  linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 75%,  rgba(1, 13, 24, 99) 95%), url(./grid-images/amoradanza.jpeg);
}
.item-grid.amoradanza:hover {
  background-image:
  linear-gradient(180deg, rgba(255, 255, 255, 0),
  rgba(1, 13, 24, 0.5) 90.71%), url(./grid-images/amoradanza.jpeg);
}


.grid-container, .servicios {
  max-width: 1300px;
  margin: auto;
  padding:64px 20px;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 10px;
}

.gallery img {
  width: 100%;
  height: 164px;
  cursor: pointer;
  object-fit: cover;
  object-position: top;
  border-radius: 5px;
  box-shadow: 1px 3px 24px -13px rgba(66, 68, 90, 1);
  transition: 0.7s;
}
.gallery img:hover {
  box-shadow: 1px 3px 24px 1px rgb(91 168 44);
}

.main-section {
  padding:90px 20px;
  max-width: 1280px;
  margin: 0 auto;
  display: grid;
}
.container-section {
display: flex;
flex-direction: column;
gap: 30px;
}
@media (min-width:992px){
  .container-section { 
    flex-direction: row;
  }
  .container-section > article {
    flex: 1.5;
  }
  .container-section > div {
    flex: 1;
  }
}
.main-section .react-tabs__tab:focus:after {
  display: none;
}
.main-section .react-tabs__tab-list {
  border-bottom: transparent;
}
.main-section .tab-content {
  padding: 0 10px;
}
.item-grid-container img {
  width: 100%;
}
@media (min-width:992px){ 

  .grid-obras.otherworks {
    grid-template-columns: repeat(4, 1fr);
  }
}
.otherworks .item-grid {
  padding:0;
  height: 100%;
}
.otherworks .item-grid-container {
  height: 100%;
}
.otherworks .item-grid-container img {

  height: 100%;
  object-fit: cover;
}

@media (max-width:768px) {
  header .logo {
    flex: 0.5;
  }
}
.acerca img {
  text-align: center;
  margin: auto;
  max-width: 100%;
  border-radius: 15px;
}

@media (min-width:768px){
  .integrantes-info {
    display: flex;
    gap:20px;
  }
  .integrantes-info > div {
    flex: 1;
  }
} 
form.contacto {
  display: grid;
  justify-items: start;
  gap: 5px;
}

form.contacto input[type="text"],
form.contacto input[type="email"],
form.contacto textarea {
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

form.contacto input[type="submit"] {
  padding: 8px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  margin-top: 5px;
}

.video-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%;
  border-radius: 15px;
}
iframe.videoembed {
  position: absolute; 
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}
.video-text {
  display: grid;
  gap:20px;
  padding: 20px 0;
}

@media (min-width: 992px) {
  .video-text { 
    grid-template-columns: 1fr 1fr;
  }
}
.msj-errorform {
  color: rgb(255 90 90);
}
.hide {
  display: none;
}

.show {
  display: block;
}
.msj-success-form {
  color: #7dff7d;
}